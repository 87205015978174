import React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

const CardContainer = styled.div`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: ${props => props.shadow ? '0 8px 36px rgba(24, 31, 73, 0.1)' : 'none'};
  height: ${props => props.height};
  width: ${props => props.width};
  padding: ${props => props.padding ? props.padding : '40px'};
  @media (max-width: 960px) {
  padding: 0;
  }
`;

const Image = styled.img`
  width: ${props => props.width};
  margin-bottom: ${props => props.smallCard ? '12px' : '24px'}
`


const HorizontalCard = props => {
  const {
    icon,
    title,
    text,
    hasBoxShadow,
    height,
    imgWidth,
    width,
    padding,
    smallCard,
  } = props;

  return (
    <CardContainer
      shadow={hasBoxShadow}
      height={height}
      width={width}
      padding={padding}
    >
      {icon && (
        <Image
          width={imgWidth}
          smallCard={smallCard}
          src={icon}
          alt={title}
        />
      )}
      <Typography
        variant={smallCard ? 'h5' : 'subtitle1'}
        align="center"
        color="textPrimary"
      >
        {title}
      </Typography>
      <Typography align="center" variant="body2" color="textPrimary">
        {text}
      </Typography>
    </CardContainer>
  );
};

export default HorizontalCard;
